<template>
    <div>

        <p>{{ getMessage }}</p>

        <div id="person_buttons">
            <button
                class="button_persons ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                v-for="index in maxNumberOfPeople"
                :key="index"
                role="button" 
                aria-disabled="true"
                @click="nPeopleSelected(index)"
            >
                <span class="ui-button-text">{{ index }}</span>
            </button>
            <button
                v-if="maxNumberOfPeople && maxNumberOfPeople > 0 && requestMorePeopleLink"
                class="button_persons ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                role="button" 
                aria-disabled="true"
            >
                <a 
                    :href="requestMorePeopleLink"
                    class="ui-button-text"
                    style="color:white"
                >
                    {{ maxNumberOfPeople + 1 }}+
                </a>
            </button>
            <p v-if="maxNumberOfPeople">{{ getMultiplePeopleAllowdMessage }}</p>
        </div>
    </div>
</template>

<script lang="js">

import HelperFunctions from '@/mixins/HelperFunctions.js'

export default {
    name: 'SelectNumberOfPeopleComponent',
    data() {
        return {
        }
    },
    props: ['data', 'dataSelected', 'restaurantData', 'restaurantID'],
    mixins: [HelperFunctions],
    computed: {
        getMessage(){
            if (this.lang.name === '') return ''

            const restaurantMessage = this.safeJsonParse(this.restaurantData.texts['select_no_of_ppl_text'])[this.lang.name]
            return restaurantMessage ? restaurantMessage : this.restaurantData.languages[this.lang.name]['vaelg_antal_personer_beskrivelse']
        },
        getMultiplePeopleAllowdMessage() {
            if (this.lang.name === '') return ''

            const restaurantMessage = this.safeJsonParse(this.restaurantData.texts['more_then_allowed_persons_text'])[this.lang.name]
            var message = restaurantMessage
            if (!restaurantMessage) {
                message = this.restaurantData.languages[this.lang.name]['flere_personer_end_maks']
                message = message.replace("{persons}", this.maxNumberOfPeople + 1).replace("{phone}", this.restaurantData.company_phone)
            } 

            return message 
        },
        maxNumberOfPeople() {
            const values = Object.values(this.data)
            const maxNumberOfPeople = values[values.length-1]

            const str = this.lang.company['flere_personer_end_maks']
            if (str === undefined) {
                return 1
            }
            return maxNumberOfPeople
        },
        requestMorePeopleLink() {
            if (!this.restaurantID) return false
            if (!this.restaurantData.max_restaurant_capacity || this.restaurantData.max_restaurant_capacity <= 0) return false

            if (isNaN(this.restaurantID)) {
                return `https://www.${this.restaurantID}/online_booking_group.php`
            } else {
                return `https://www.bord-booking.dk/online_booking_group.php?restaurantid=${this.restaurantID}`
            }

        },
        lang() {
            return this.$store.state.lang
        }
    },
    methods: {
        nPeopleSelected(number) {
            this.dataSelected({
                'numberOfPeople': number
            })
        }
    },
}
</script>


